<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Use the SDS available
        <a
          href="https://www.flinnsci.com/sds_801.02-sulfuric-acid-solution-1-m---6-m/sds_801.02/"
          rel="noopener noreferrer"
          target="_blank"
          >here</a
        >, to help you answer the following questions about
        <stemble-latex content="$\ce{H2SO4}." />
      </p>

      <p class="mb-2">
        a) What is the name of the chemical according to the SDS (ignore the "solution" and
        concentration)??
      </p>

      <v-text-field
        v-model="inputs.compoundName"
        outlined
        class="mb-n2"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) What is the chemical formula?.</p>

      <chemical-notation-input v-model="inputs.molFormula" class="mb-5" :disabled="!allowEditing" />

      <p class="mb-n2 mt-5">c) What is the best choice for the reactivity of the compound?</p>

      <v-radio-group v-model="inputs.reactivity" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        d) What <b>hazards</b> could the chemical pose? Note that this may require very large
        quantities.
      </p>

      <p v-for="option in options2" :key="'pt-4-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.hazards" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';

export default {
  name: 'SdsSulfuricAcid',
  components: {StembleLatex, ChemicalNotationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        compoundName: null,
        molFormula: null,
        reactivity: null,
        hazards: [],
      },
      options1: [
        {text: '$\\text{Reacts with acids}$', value: 'a'},
        {text: '$\\text{Reacts with bases}$', value: 'b'},
        {text: '$\\text{Reacts with metals}$', value: 'c'},
        {text: '$\\text{Reacts with acids and metals}$', value: 'd'},
        {text: '$\\text{Reacts with bases and metals}$', value: 'e'},
        {text: '$\\text{Does not react with any of the above}$', value: 'f'},
      ],
      options2: [
        {text: '$\\text{Flammable}$', value: 'a'},
        {text: '$\\text{Severe skin burns}$', value: 'b'},
        {text: '$\\text{Severe eye irritant}$', value: 'c'},
        {text: '$\\text{Do not inhale}$', value: 'd'},
        {text: '$\\text{Harmful if swallowed}$', value: 'e'},
        {text: '$\\text{Acute toxicity}$', value: 'f'},
        {text: '$\\text{None of the above}$', value: 'g'},
      ],
    };
  },
};
</script>
<style scoped>
.v-input--checkbox {
  display: inline-block !important;
}
</style>
